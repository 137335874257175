// 29/10/2010:  [AD] Prevent use of _ by default
// 03/11/2010:  [TP] Fix applied to defect #3691 - allow copy/paste operations under firefox - a change in keypress function has been made
// 03/11/2010:  [TP] Attached on change event and skip all unallowed characters from the input field value.
// 15/03/2011:  [AA] Modified numeric() to ignore characters � and �, by using the unicode symbols
(function ($) {

    $.fn.alphanumeric = function (p) {

        p = $.extend({
            ichars: '!@#$_�%^&*()+=[]\\\';,/{}|\":<>?~`.- \u00A3\u00AC',
            nchars: '',
            allow: ''
        }, p);

        // HK : 01-06-2010 : Store a copy of the original 'Defaults' option as set by the user in the code.
        var originalAllow = p.allow;

        return this.each
			(
				function () {

				    if (p.nocaps) p.nchars += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
				    if (p.allcaps) p.nchars += 'abcdefghijklmnopqrstuvwxyz';

				    // HK : 01-06-2010 : Resasign the original 'Defaults' option as set by the 
				    // user in the code.
				    // If this is not done the existing code will just concatenate p.allow on 
				    // every iteration of the character that is there in the text box to which it 
				    // is bound and blow up the memory of IE and FF.
				    // IE will return a 'Out of Memory' error and FF will give dialog saying
				    // 'A script is too busy bla bla bla yada yada yawn ...' dialog
				    p.allow = originalAllow;

				    s = p.allow.split('');
				    //				    for (i = 0; i < s.length; i++) if (p.ichars.indexOf(s[i]) != -1) s[i] = '\\' + s[i];
				    for (i = 0; i < s.length; i++) {
				        if (p.ichars.indexOf(s[i]) != -1) {
				            s[i] = '\\' + s[i];
				        }
				    }
				    p.allow = s.join('|');

				    var reg = new RegExp(p.allow, 'gi');
				    var ch = p.ichars + p.nchars;
				    ch = ch.replace(reg, '');

				    $(this).keypress
						(
							function (e) {

							    if (!e.charCode) k = String.fromCharCode(e.which);
							    else k = String.fromCharCode(e.charCode);

							    // [TP]: Under Firefox users need to be able to paste values in the input fields
							    // [TP]: This line of code suppres paste operation (fix applied to defect #3691)
							    if ((e.ctrlKey && k == 'v') || (e.ctrlKey && k == 'c')) {
							        // do nothing
							    }
							    else {

							        if (ch.indexOf(k) != -1) {
							            e.preventDefault();
							        }
							    }
							}
						);

				    $(this).change
						(
							function () {
							    var value = $(this).val();
							    var tmpVal = value;
							    if (value.length == 0) {
							        return;
							    }
							    var stringArray = value.split('');
							    for (var i = 0; i < stringArray.length; i++) {
							        if (ch.indexOf(stringArray[i]) != -1) {
							            // remove the character
							            tmpVal = tmpVal.replace(stringArray[i], '');
							        }
							    }
							    $(this).val(tmpVal);
							}
						);

				    $(this).bind('contextmenu', function () { return false });

				}
			);

    };

    $.fn.numeric = function (p) {

        var az = 'abcdefghijklmnopqrstuvwxyz\u00A3\u00AC';
        az += az.toUpperCase();

        p = $.extend({
            nchars: az
        }, p);

        return this.each(function () {
            $(this).alphanumeric(p);
        }
		);

    };

    $.fn.alpha = function (p) {

        var nm = '1234567890';

        p = $.extend({
            nchars: nm
        }, p);

        return this.each(function () {
            $(this).alphanumeric(p);
        }
		);

    };

})(jQuery);